.registerPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eventsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.eventCard {
    width: 300px; /* Adjust based on your preference */
    margin: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center;
    border-radius: 8px;
}

.registerButton {
    margin: 10px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.registerButton:hover {
    background-color: #0056b3;
}
