.resultPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resultsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.resultCard {
    width: 300px; /* Adjust based on your preference */
    margin: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center;
    border-radius: 8px;
}
.newlabel {
    color: red;
    font-weight: bold;
    animation: blinker 0.3s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
/* Add other styles as needed */
