.feedback-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 300px; /* Adjust width as needed */
    margin: 20px;
  }
  
  .feedback-card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.4);
  }
  
  .feedback-image {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    object-fit: cover;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .feedback-name {
    margin: 0;
    color: #333;
    font-size: 20px;
    font-weight: bold;
  }
  
  .feedback-text {
    font-size: 16px;
    color: #666;
    margin: 10px 0 20px;
  }
  
  .star-rating {
    color: #ffd700;
  }
  
  .fa-star {
    margin-right: 5px;
  }
  
  .checked {
    color: orange;
  }
  