.countdown-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .time-box {
    background: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #333;
  }
  
  .time-box span {
    font-size: 0.75rem;
    color: #666;
    margin-top: 4px;
  }
  
  @media (max-width: 600px) {
    .countdown-timer {
      flex-direction: column;
    }
  }
  