/* ArtPaymentGateway.css */

.payment-container {
    background-color: #f0f0f0;
    padding: 20px;
    width: 90%; /* Adjust width for responsiveness */
    max-width: 400px; /* Maximum width for larger screens */
    margin: 20px auto; /* Center the component and add space from top */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-field {
    width: calc(100% - 20px); /* Adjust width to accommodate padding */
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.pay-now-btn {
    width: 100%; /* Make button full-width */
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.pay-now-btn:hover {
    background-color: #0056b3;
}
