.tncContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
    max-width: 800px; /* Adjust based on your preference */
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.heading {
    color: #333;
    margin-bottom: 20px;
}

.subHeading {
    color: #444;
    margin-top: 20px;
    margin-bottom: 10px;
}

.subHeadingSmall {
    color: #555;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text {
    text-align: justify;
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 1rem; /* Adjust font size as needed */
}
