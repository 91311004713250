a {
    color: rgb(255 255 255);
    text-decoration: none;
}

.info-cards-container {
    /* display: flex; */
    /* justify-content: space-around; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
    flex-wrap: wrap;
}

.info-card {
    background-color: #f0f4f8;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: calc(50% - 40px);
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

.info-card:hover {
    transform: translateY(-5px);
}

.info-card h2 {
    color: #333;
    margin-bottom: 15px;
}

.info-content h3 {
    color: #555;
}

.info-content p {
    color: #666;
    margin-bottom: 10px;
}

.info-card button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.info-card button:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .info-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .info-card {
        width: 80%;
    }
}